import dynamic from 'next/dynamic'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { MdFileDownload } from 'react-icons/md'

const ArrowIcon = dynamic(() =>
  import('./ArrowIcon').then((res) => res.ArrowIcon)
)
const BagIcon = dynamic(() => import('./BagIcon').then((res) => res.BagIcon))
const BellIcon = dynamic(() => import('./BellIcon').then((res) => res.BellIcon))
const ChatIcon = dynamic(() => import('./ChatIcon').then((res) => res.ChatIcon))
const ChainIcon = dynamic(() =>
  import('./ChainIcon').then((res) => res.ChainIcon)
)
const CheckIcon = dynamic(() =>
  import('./CheckIcon').then((res) => res.CheckIcon)
)
const ChecklistIcon = dynamic(() =>
  import('./ChecklistIcon').then((res) => res.ChecklistIcon)
)
const CloseIcon = dynamic(() =>
  import('./CloseIcon').then((res) => res.CloseIcon)
)
const CompaniesIcon = dynamic(() =>
  import('./CompaniesIcon').then((res) => res.CompaniesIcon)
)
const DashboardIcon = dynamic(() =>
  import('./DashboardIcon').then((res) => res.DashboardIcon)
)
const EditorIcon = dynamic(() =>
  import('./EditorIcon').then((res) => res.EditorIcon)
)
const EllipsisIcon = dynamic(() =>
  import('./EllipsisIcon').then((res) => res.EllipsisIcon)
)
const ExclamationIcon = dynamic(() =>
  import('./ExclamationIcon').then((res) => res.ExclamationIcon)
)
const ExpandIcon = dynamic(() =>
  import('./ExpandIcon').then((res) => res.ExpandIcon)
)
const EyeIcon = dynamic(() => import('./EyeIcon').then((res) => res.EyeIcon))
const FilterIcon = dynamic(() =>
  import('./FilterIcon').then((res) => res.FilterIcon)
)
const ForumIcon = dynamic(() =>
  import('./ForumIcon').then((res) => res.ForumIcon)
)
const GearIcon = dynamic(() => import('./GearIcon').then((res) => res.GearIcon))
const IndustriesIcon = dynamic(() =>
  import('./IndustriesIcon').then((res) => res.IndustriesIcon)
)
const InsightsIcon = dynamic(() =>
  import('./InsightsIcon').then((res) => res.InsightsIcon)
)
const InvitesIcon = dynamic(() =>
  import('./InvitesIcon').then((res) => res.InvitesIcon)
)
const JobTitleIcon = dynamic(() =>
  import('./JobTitleIcon').then((res) => res.JobTitleIcon)
)
const LanguageIcon = dynamic(() =>
  import('./LanguageIcon').then((res) => res.LanguageIcon)
)
const LinkedInIcon = dynamic(() =>
  import('./LinkedInIcon').then((res) => res.LinkedInIcon)
)
const LocationIcon = dynamic(() =>
  import('./LocationIcon').then((res) => res.LocationIcon)
)
const LockIcon = dynamic(() => import('./LockIcon').then((res) => res.LockIcon))
const LogoIcon = dynamic(() => import('./LogoIcon').then((res) => res.LogoIcon))
const MagnifyGlassIcon = dynamic(() =>
  import('./MagnifyGlassIcon').then((res) => res.MagnifyGlassIcon)
)
const MailingIcon = dynamic(() =>
  import('./MailingIcon').then((res) => res.MailingIcon)
)
const MyJobsIcon = dynamic(() =>
  import('./MyJobsIcon').then((res) => res.MyJobsIcon)
)
const PaperplaneIcon = dynamic(() =>
  import('./PaperplaneIcon').then((res) => res.PaperplaneIcon)
)
const PhoneIcon = dynamic(() =>
  import('./PhoneIcon').then((res) => res.PhoneIcon)
)
const ProfileIcon = dynamic(() =>
  import('./ProfileIcon').then((res) => res.ProfileIcon)
)
const QuestionIcon = dynamic(() =>
  import('./QuestionIcon').then((res) => res.QuestionIcon)
)
const RewardsIcon = dynamic(() =>
  import('./RewardsIcon').then((res) => res.RewardsIcon)
)
const SalaryIcon = dynamic(() =>
  import('./SalaryIcon').then((res) => res.SalaryIcon)
)
const SettingsIcon = dynamic(() =>
  import('./SettingsIcon').then((res) => res.SettingsIcon)
)
const StarIcon = dynamic(() => import('./StarIcon').then((res) => res.StarIcon))
const SurveysIcon = dynamic(() =>
  import('./SurveysIcon').then((res) => res.SurveysIcon)
)
const ThreeDotsIcon = dynamic(() =>
  import('./ThreeDotsIcon').then((res) => res.ThreeDotsIcon)
)
const TimeZoneIcon = dynamic(() =>
  import('./TimeZoneIcon').then((res) => res.TimeZoneIcon)
)
const UploadsIcon = dynamic(() =>
  import('./UploadsIcon').then((res) => res.UploadsIcon)
)
const WorkplaceIcon = dynamic(() =>
  import('./WorkplaceIcon').then((res) => res.WorkplaceIcon)
)
const ZoomInIcon = dynamic(() =>
  import('./ZoomInIcon').then((res) => res.ZoomInIcon)
)
const ZoomOutIcon = dynamic(() =>
  import('./ZoomOutIcon').then((res) => res.ZoomOutIcon)
)
const ShareIcon = dynamic(() =>
  import('./ShareIcon').then((res) => res.ShareIcon)
)
const TwitterIcon = dynamic(() =>
  import('./TwitterIcon').then((res) => res.TwitterIcon)
)
const FacebookIcon = dynamic(() =>
  import('./FacebookIcon').then((res) => res.FacebookIcon)
)
const DrawerIcon = dynamic(() =>
  import('./DrawerIcon').then((res) => res.DrawerIcon)
)

export const ICON_VARIANTS = {
  arrow: 'arrow',
  bag: 'bag',
  bell: 'bell',
  chat: 'chat',
  chain: 'chain',
  check: 'check',
  checklist: 'checklist',
  close: 'close',
  companies: 'companies',
  dashboard: 'dashboard',
  drawer: 'drawer',
  editor: 'editor',
  ellipsis: 'ellipsis',
  exclamation: 'exclamation',
  expand: 'expand',
  eye: 'eye',
  facebook: 'facebook',
  filter: 'filter',
  forum: 'forum',
  gear: 'gear',
  industries: 'industries',
  insights: 'insights',
  invites: 'invites',
  jobTitle: 'jobTitle',
  language: 'language',
  linkedIn: 'linkedIn',
  location: 'location',
  lock: 'lock',
  logo: 'logo',
  mailing: 'mailing',
  magnifyGlass: 'magnifyGlass',
  myJobs: 'myJobs',
  paperplane: 'paperplane',
  profile: 'profile',
  phone: 'phone',
  rewards: 'rewards',
  question: 'question',
  salary: 'salary',
  search: 'search',
  settings: 'settings',
  share: 'share',
  star: 'star',
  surveys: 'surveys',
  threeDots: 'threeDots',
  timeZone: 'timeZone',
  twitter: 'twitter',
  uploads: 'uploads',
  workplace: 'workplace',
  zoomIn: 'zoomIn',
  zoomOut: 'zoomOut',
  download: 'download',
  pdf: 'pdf',
}

/**
 * @property {string} addClass - Adds className to the svg (optional)
 */
export interface BaseIconProps {
  addClass?: string
  fill?: string
  circled?: boolean
}

/**
 * Props that allows selecting the specific type of the given icon
 */
export interface IconTypeProps {
  type?: string
}

/**
 * Props for icons with equal width and height
 */
export interface SquareIconProps extends BaseIconProps {
  size?: number
}

/**
 * Props for icons with different width and height
 */
export interface RectangularIconProps extends BaseIconProps {
  width?: number
  height?: number
}

export type IconProps = SquareIconProps &
  RectangularIconProps & { variant?: string; type?: string }

/**
 * A factory component that renders the appropriate Icon component
 * @param param0
 * @returns
 */
export const Icon: React.FC<IconProps> = ({ variant, ...props }) => {
  const variants = {
    arrow: <ArrowIcon {...props} />,
    bag: <BagIcon {...props} />,
    bell: <BellIcon {...props} />,
    chat: <ChatIcon {...props} />,
    chain: <ChainIcon {...props} />,
    check: <CheckIcon {...props} />,
    checklist: <ChecklistIcon {...props} />,
    close: <CloseIcon {...props} />,
    companies: <CompaniesIcon {...props} />,
    dashboard: <DashboardIcon {...props} />,
    drawer: <DrawerIcon {...props} />,
    editor: <EditorIcon {...props} />,
    ellipsis: <EllipsisIcon {...props} />,
    exclamation: <ExclamationIcon {...props} />,
    expand: <ExpandIcon {...props} />,
    eye: <EyeIcon {...props} />,
    facebook: <FacebookIcon {...props} />,
    filter: <FilterIcon {...props} />,
    forum: <ForumIcon {...props} />,
    gear: <GearIcon {...props} />,
    industries: <IndustriesIcon {...props} />,
    insights: <InsightsIcon {...props} />,
    invites: <InvitesIcon {...props} />,
    jobTitle: <JobTitleIcon {...props} />,
    language: <LanguageIcon {...props} />,
    linkedIn: <LinkedInIcon {...props} />,
    location: <LocationIcon {...props} />,
    lock: <LockIcon {...props} />,
    logo: <LogoIcon {...props} />,
    mailing: <MailingIcon {...props} />,
    myJobs: <MyJobsIcon {...props} />,
    magnifyGlass: <MagnifyGlassIcon {...props} />,
    paperplane: <PaperplaneIcon {...props} />,
    phone: <PhoneIcon {...props} />,
    profile: <ProfileIcon {...props} />,
    rewards: <RewardsIcon {...props} />,
    salary: <SalaryIcon {...props} />,
    share: <ShareIcon {...props} />,
    settings: <SettingsIcon {...props} />,
    star: <StarIcon {...props} />,
    surveys: <SurveysIcon {...props} />,
    question: <QuestionIcon {...props} />,
    threeDots: <ThreeDotsIcon {...props} />,
    timeZone: <TimeZoneIcon {...props} />,
    twitter: <TwitterIcon {...props} />,
    uploads: <UploadsIcon {...props} />,
    workplace: <WorkplaceIcon {...props} />,
    zoomIn: <ZoomInIcon {...props} />,
    zoomOut: <ZoomOutIcon {...props} />,
    download: <MdFileDownload />,
    pdf: <AiOutlineFilePdf />,
  }
  return variants[variant]
}
