import { css } from 'styled-components'

export const handleSearchInputSize = (size) => {
  switch (size) {
    case 'lg':
      return css`
        height: 48px;
      `
    case 'sm':
      return css`
        height: 38px;
      `
    case 'xs':
      return css`
        height: 28px;
      `
  }
}

export const handleDropdownInputSize = (size) => {
  switch (size) {
    case 'lg':
      return css``

    case 'sm':
      return css`
        height: 12px;
        padding: 0;
      `
  }
}

export const handleAttach = (attach, borderRadius) => {
  switch (attach) {
    case 'top':
      return css`
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: ${borderRadius}px;
        border-bottom-right-radius: ${borderRadius}px;
        border-top: none;
      `
    case 'bottom':
      return css`
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: ${borderRadius}px;
        border-top-right-radius: ${borderRadius}px;
        border-bottom: none;
      `
    case 'left':
      return css`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: ${borderRadius}px;
        border-bottom-right-radius: ${borderRadius}px;
        border-left: 0;
      `
    case 'right':
      return css`
        border-top-left-radius: ${borderRadius}px;
        border-bottom-left-radius: ${borderRadius}px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      `
    case 'all':
      return css`
        border-radius: 0px;
      `
    default:
      return css`
        border-radius: ${borderRadius}px;
      `
  }
}

export const handleDropdownInputBackground = (transparent, value, theme) => {
  if (transparent) {
    return 'rgba(0,0,0,0)'
  } else {
    return theme.background.tertiary
  }
}
