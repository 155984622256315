import styled from 'styled-components'
import { PHONE_BREAKPOINT } from 'styles/breakpoints'
import { transition1 } from 'styles/transformation'
import { handleFontSize, handleFontWeight } from 'styles/typography'
import { handleAttach } from './mixin'

export const InputContainer = styled.div<{
  grow?: boolean
  attach?: null | 'top' | 'bottom' | 'left' | 'right' | 'all'
  transparent?: boolean
  stretch?: boolean
  size?: string
}>`
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  ${({ attach }) => handleAttach(attach, 4)};
  width: ${({ stretch }) => stretch && '100%'};
  flex-grow: ${({ grow }) => (grow ? 1 : 'inherit')};
  height: ${({ size }) => (size === 'lg' ? 'fit-content' : '45px')};
`

export const InputElement = styled.input<{
  transparent?: boolean
  noTransition?: boolean
}>`
  border: 1px solid ${({ theme }) => theme.border} !important;
  background-color: ${({ theme }) => theme.background.primary};
  border-radius: inherit;
  outline: none;
  color: ${({ theme }) => theme.primary};
  box-shadow: none;
  font-size: ${() => handleFontSize('lg')};
  font-weight: ${() => handleFontWeight('light')};
  width: 100%;
  height: 100%;
  ${({ noTransition }) => !noTransition && transition1};

  :focus {
    box-shadow: ${({ theme }) => `0px 0px 0px 1px ${theme.border}`};
  }

  @media screen and (max-width: ${PHONE_BREAKPOINT}px) {
    :focus {
      box-shadow: ${({ theme }) => `0px 0px 0px 1.5px ${theme.border}`};
    }
  }

  ::placeholder {
    color: ${({ theme }) => theme.secondary};
  }

  -webkit-appearance: none;

  // CHROME INPUT AUTOFILLS
  :-webkit-autofill,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 40px ${({ theme }) => theme.background.primary}
      inset !important;
  }

  :-webkit-autofill:hover {
    -webkit-box-shadow: ${({ theme }) =>
          `0 0 0 40px ${theme.background.primary}`}
        inset,
      ${({ theme }) => `0 0 0 1px ${theme.background.primary}`} !important;
  }

  :-webkit-autofill {
    -webkit-text-fill-color: ${({ theme }) => theme.primary} !important;
  }
`
